<template>

    <div class="modal fade show d-block ps--scrolling-y" tabindex="-1" role="dialog" >
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ $t('default.select_instagram_account')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">


            <v-wait for="account-list">

                <loading slot="waiting"/>
              <p>{{ $t('default.select_instagram_account_desc')}} <strong>{{ accounts.account.name }}</strong>:</p>
              <b-row class="overflow-hidden">
                <template v-for="account in accounts.data">
                  <b-col md="6" sm="12" @click="choose_account(accounts.account.id, account, accounts.integration)">
                    <b-card class="overflow-hidden mb-4 instagram-select-account-card">
                      <b-row>
                        <b-col md="4" class="instagram-select-account-image">
                          <b-card-img :src="account.profile_picture_url" width="180" alt="Image" style="border-radius:50%;border: #cccccc solid 1px;"></b-card-img>
                        </b-col>
                        <b-col md="8" class="instagram-select-account-card-body">
                          <h4 class="instagram-select-account-card-title">
                            <span>{{ account.name }}</span>
                          </h4>
                          <p><strong>{{ $t('simple.username')}}</strong> : {{ account.username }}</p>
                          <p><strong>{{ $t('customer.following')}}</strong> : {{ account.follows_count }}</p>
                          <p><strong>{{ $t('customer.followers')}}</strong> : {{ account.followers_count }}</p>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                </template>

              </b-row>
            </v-wait>


          </div>
        </div>
      </div>
    </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

    export default {

        mounted: async function () {
            // Component mounted
            console.log('Instagram Select Account Modal mounted');

            this.fetchAccountList({id: this.id, integration: this.integration})
                .then((response) => {

                }).catch((error) => {
                this.$close(false)
            })

            // // Get integrations
            // this.getIntegrations();
            //
            // // Get teams
            // this.getTeams();
        },
        
        destroyed() {
            console.log('Instagram Select Account Modal destroyed.');
        },

        data: function() {

             return  {
                 
                 accounts_api: '/api/v1/settings/accounts/selection/',
                 integrations_api: '/api/v1/settings/integrations',
                 teams_api: '/api/v1/system/teams',

                 integrations: [],
                 api_providers: [
                     {
                        id : 'waboxapp',
                        name : 'WaboxApp'
                     },
                     {
                        id : 'karix',
                        name : 'Karix'
                     },
                 ],
                 teams: [],

                 integration: '',
                 api_provider:'',
                 name: '',
                 username: '',
                 password: '',
                 phone: '',
                 confirmation: false,
                 team: false,

            }

        },

        computed: {

          // Map getters
          ...mapGetters({

            // Customers
            accounts: 'Accounts/getInstagramAccountList',

          }),

        },

        methods: {

          // Map actions
          ...mapActions('Accounts', [
            'fetchAccountList',
            'chooseAccount',
          ]),
          choose_account: function(id, account_data, integration){
             let modal=this;
             this.chooseAccount({id, account_data, integration}).then(function () {
               modal.$close(true);
            }).catch(function (err){
               modal.$close(false)
            })

          },


        },
    }
</script>


